import { useEffect, useRef, useState } from "react";
import { Footer } from "./Footer";
import { GalleryProp, GalleryItems, NewGalleryItems } from "./GalleryItems";

const ImageComponent: React.FC<{
  src: string;
  description: string;
  float: string;
}> = ({ src, description, float }) => {
  const [percentageWidth, setPercentageWidth] = useState<string>("100%");
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imgRef.current) {
      const calculatedPercentage =
        imgRef.current.naturalHeight / imgRef.current.naturalWidth > 1.5
          ? "55%"
          : "70%";
      setPercentageWidth(calculatedPercentage);
    }
  }, []);

  return (
    <img
      ref={imgRef}
      src={src}
      style={{ width: percentageWidth, float: float as any, borderRadius: 3 }}
      alt={description}
    />
  );
};

const GalleryItem: React.FC<GalleryProp & { invert: boolean }> = ({
  image,
  name,
  year,
  description,
  size,
  invert,
}) => {
  const textComponent = (
    <div style={{ width: "48%" }}>
      <h4>
        {`${name}.`}
        <br />
        {`${year}.`}
        <br />
        {`${description}.`}
        <br />
        {`${size}.`}
      </h4>
    </div>
  );
  const imageComponent = (
    <div style={{ width: "52%" }}>
      <ImageComponent
        src={image}
        description={description}
        float={invert ? undefined : ("right" as any)}
      />
    </div>
  );

  return (
    <div className="row">
      {invert ? imageComponent : textComponent}
      {invert ? textComponent : imageComponent}
    </div>
  );
};

export const Gallery: React.FC = () => {
  return (
    <div id="page-wrapper">
      <article id="main">
        <header>
          <h2>Gallery</h2>
          <p style={{ backgroundImage: "url(images/1s.jpg)" }}>
            Windows peering into different worlds
          </p>
        </header>
        <section id="one" className="wrapper style1 special">
          <div className="inner">
            <header className="major">
              <h2>Store</h2>
              <p>Please visit my Etsy store to purchase any piece</p>
              <ul className="icons">
                <li>
                  <a
                    href="https://www.etsy.com/ca/shop/KobeNArt?ref=seller-platform-mcnav"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fab fa-etsy"
                  >
                    <span className="label"></span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/people/Kobe-N-Art/pfbid0b6QZMMkBJQzZiPUaDatBM6Zo7aBkWGRNcf6kKZqB88uyhMHyDJuXnKA4wZVQ8jY2l/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-facebook-f"
                  >
                    <span className="label">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/kobe_n_art/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon brands fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <ul className="copyright">
                  <li>
                    <a href="mailto:art.n.kobe@gmail.com">
                      Email: art.n.kobe@gmail.com
                    </a>
                  </li>
                </ul>
              </ul>
            </header>
          </div>
        </section>
        <section className="wrapper style5">
          <div className="inner">
            <section>
              <div className="box alt">
                {GalleryItems.map((g, i) => (
                  <div key={i}>
                    <GalleryItem {...g} invert={i % 2 === 0} />
                    <hr />
                  </div>
                ))}
                <div className="row ">
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/Wordless1.jpg" alt="" />
                    </span>
                  </div>
                  <div className="col-3">
                    <span className="image fit">
                      <img src="images/Wordless2.jpg" alt="" />
                    </span>
                  </div>

                  <div className="col-6">
                    <h4>
                      Wordless.
                      <br />
                      2023.
                      <br />
                      Acrylic, Inc, mixed media on stretched canvas.
                      <br />
                      25 cm by 51 cm by 4 cm. (left)
                      <br />
                      <br />
                      Some words.
                      <br />
                      2023.
                      <br />
                      Acrylic, Inc, mixed media on stretched canvas.
                      <br />
                      25 cm by 51 cm by cm. (right)
                    </h4>
                  </div>
                </div>
                <hr />
                {NewGalleryItems.map((g, i) => (
                  <div key={i}>
                    <GalleryItem {...g} invert={i % 2 !== 0} />
                    <hr />
                  </div>
                ))}
              </div>
            </section>
          </div>
        </section>
      </article>
      <Footer />
    </div>
  );
};
