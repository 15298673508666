import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Gallery } from "./Gallery";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Commissions } from "./Commisions";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const scriptsNames = ["jquery.min.js", "jquery.scrolly.min.js", "jquery.scrollex.min.js", "browser.min.js", "breakpoints.min.js", 'util.js', 'main.js',];
const scriptsNames = ["util.js", "main.js"];

const AppRoutes = () => {
  // useEffect(() => {
  //   const scripts: Node[] = [];
  //   scriptsNames.forEach(s => {
  //     const script = document.createElement('script');
  //     script.src = `/assets/js/${s}`;
  //     scripts.push(script)
  //     document.body.appendChild(script);
  //   })

  //   return () => {
  //     scripts.forEach(s => document.body.removeChild(s))
  //   };
  // }); // Empty dependency array means this effect runs once after initial render

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/commiss" element={<Commissions />} />
        <Route path="/*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};
root.render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
