export const Footer: React.FC = () => {
    return <footer id="footer">
        <ul className="icons">
            <li>
                <a href="https://www.etsy.com/ca/shop/KobeNArt?ref=seller-platform-mcnav"
                    target="_blank" rel="noopener noreferrer" className="fab fa-etsy"><span
                        className="label"></span></a>
            </li>
            <li>
                <a href="https://www.facebook.com/people/Kobe-N-Art/pfbid0b6QZMMkBJQzZiPUaDatBM6Zo7aBkWGRNcf6kKZqB88uyhMHyDJuXnKA4wZVQ8jY2l/"
                    target="_blank" rel="noopener noreferrer" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a>
            </li>
            <li><a href="https://www.instagram.com/kobe_n_art/" target="_blank" rel="noopener noreferrer"
                className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
            <ul className="copyright">
                <li><a href="mailto:art.n.kobe@gmail.com">Email: art.n.kobe@gmail.com</a></li>
            </ul>
        </ul>
        <ul className="copyright">
            <li>&copy; Kobe N.</li>
            <li>Maintained by: <a href="https://lilays.com" target="_blank" rel="noopener noreferrer">Lilays</a></li>
            <li>Design: <a href="http://html5up.net" target="_blank" rel="noopener noreferrer">HTML5 UP</a></li>
        </ul>
    </footer>
}