import { Footer } from "./Footer";

function App() {
  document.getElementById("header")?.classList.add("alt");

  return (
    <div id="page-wrapper">
      <section id="banner">
        <div className="inner">
          <h2>Kobe N</h2>
          <p style={{ backgroundImage: "url(images/City.jpg)" }}></p>
        </div>
        <a href="#one" className="more scrolly">
          Explore wonders
        </a>
      </section>

      <section id="one" className="wrapper style1 special">
        <div className="inner">
          <header className="major">
            <h2>Experience beautiful chaos</h2>
            <p>
              Kobe N, a visionary artist whose creations embody the allure of
              abstract concepts. Her artwork seamlessly blends elements of
              harmonious chaos, resulting in breathtaking amalgamations that
              defy conventional boundaries. Through a masterful interplay of
              brushstrokes, her art becomes a portal to boundless imagination,
              it evokes emotions and ideas that transcend words. Her work serves
              as a testament to the profound beauty that exists within the
              enigmatic realm of perfect harmony, reminding us of the intricate
              interconnections and vibrant complexities present within our
              world.
            </p>
            <p>
              Kobe N has spent a year under Andrew Hamilton, a Canadian drawing
              master and a professor of fine arts. Hamilton is apprenticed by
              acclaimed Canadian landscape painter Doris McCarthy. Hamilton,
              pushed Kobe to master her style and paint with an open mind to
              create truly unique pieces.
            </p>
          </header>
        </div>
      </section>

      <section id="three" className="wrapper style3 special">
        <div className="inner">
          <ul className="features">
            <li className="icon fa-solid fa-image">
              <a href="gallery">
                <h3>Gallery</h3>
                <p>Go on a journey to various worlds</p>
              </a>
            </li>

            <li className="icon solid fa-portrait">
              <a href="commiss">
                <h3>Commissions</h3>
                <p>Custom pieces</p>
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <p>
            “The world always seems brighter when you've just made something
            that wasn't there before.” - Neil Gaiman
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
